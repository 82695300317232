import { breakpoints, v2Colors } from '@web-for-marketing/react-core';

export const MarketplaceBoxStyles = {
    title: {
        marginBottom: '2.5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    description: {
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginBottom: '2rem',
        },
    },
    buttonItem: {
        marginTop: '2.5rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            marginTop: '0',
        },
    },
    contentContainer: {
        paddingRight: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            order: 2,
            paddingRight: '0',
        },
    },
    marketplaceBoxImage: {
        paddingLeft: '3rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            order: 1,
            paddingLeft: '0',
            marginBottom: '2.5rem',
        },
    },
    marketplaceBoxContainer: {
        backgroundColor: v2Colors.background.light,
        borderRadius: '8px',
        padding: '2rem 7rem',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '3.5rem',
        },
    },
} as const;
