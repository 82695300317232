import React from 'react';
import { Grid } from '@mui/material';
import { MarketplaceBoxStyles as classes } from './styleObjects/MarketplaceBoxStyles';
import { Picture } from '../Picture';
import { Heading, Text, LazyLoadContainer } from '@web-for-marketing/react-ui';
import { CMSLink } from '@components/CMSLink';
import { CustomSectionComponentProps } from '@models/customSection';
import MarketPlaceIcons from '@images/MarketplaceBox/marketplace-icons.png';
import { SectionContainer } from './SectionContainer';

export default function MarketplaceBox({ lazyLoad, headerSection }: CustomSectionComponentProps): JSX.Element {
    return (
        <SectionContainer>
            <LazyLoadContainer css={{ width: '100%' }} lazyload={lazyLoad}>
                <Grid container css={classes.marketplaceBoxContainer} alignItems='center'>
                    <Grid item xs={12} md={6} justifyContent='flex-start' css={classes.contentContainer}>
                        <Heading component={headerSection ? 'h1' : 'h2'} variant='h3' weight='bold' css={classes.title}>
                            Remove boundaries to your innovation.
                        </Heading>
                        <Text weight='regular' css={classes.description}>
                            Integrate the things that matter to your business. Take advantage of solutions and expertise
                            from our diverse ecosystem and partner network to achieve your goals.
                        </Text>
                        <CMSLink
                            type='button'
                            href={'https://marketplace.geotab.com/'}
                            css={classes.buttonItem}
                            variant='outlined'
                        >
                            See Solutions
                        </CMSLink>
                    </Grid>
                    <Grid container item xs={12} md={6} css={classes.marketplaceBoxImage}>
                        <Picture
                            alt='Grid of icons representing different solution integrations'
                            imagePath={MarketPlaceIcons}
                            lazyLoad={lazyLoad}
                            aspectRatio='600/330'
                        />
                    </Grid>
                </Grid>
            </LazyLoadContainer>
        </SectionContainer>
    );
}
